$(document).ready(function () {
  function handleDropDownSuggestion ($component) {
    if (!$component || !$component.length) {
      return;
    }

    $component.unbind().removeData();
    return $component.drpAutoCompleteEnhanced();
  }

  function populateDropdown (config) {
    const { data = [], defaultValue = {}, $component, $templateContainer } = config;

    autoRenderHTML($templateContainer, data);
    renderDefaultValue($component, defaultValue);

    return handleDropDownSuggestion($component);
  }

  function renderDefaultValue ($component, defaultValue) {
    if (!$component || !$component.length) {
      return;
    }

    const $input = $component.find('input[type="text"]');
    window.updateDataField($input, defaultValue.text);
    $input.attr('data-value', defaultValue.value);
  }

  function autoRenderHTML ($templateContainer, data) {
    if (!$templateContainer || !$templateContainer.length) {
      return;
    }

    $templateContainer.find('li.js-drp-item').remove();

    const $subItemTemplate = $('#itemTemplate');
    const $itemTemplate = $($subItemTemplate.html());

    data.forEach(item => {
      const $newItem = $itemTemplate.clone();
      const $element = $newItem.find('a');
      $element.attr('data-value', item.value || item);
      $element.attr('data-text', item.text || item);
      $element.attr('data-related', item.related || item);
      $element.html(item.text || item);

      $templateContainer.append($newItem);
    });
  }

  // export
  window.populateDropdownEnhanced = populateDropdown;
});
